import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MobileNavigation.css';
import logo from '../../logo_images/Stevie_logo.png';
import micro_logo from '../../logo_images/micro_logo.png'
import nav_links from '../../data/nav_links'

function MobileNavigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [shadow, setShadow] = useState(false);

  // Detect scroll to add shadow
  useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setShadow(true);
        } else {
            setShadow(false);
        }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const filteredNavLinks = nav_links.slice(0, 4);


  return (
    <div className={`MobileNavigation ${shadow ? 'shadow' : ''}`}>
      <Link className="Mobile-App-header" to='/'>
        <img src={logo} alt='logo' className='logo' />
        <img src={micro_logo} alt='logo' className='micro-logo' />
      </Link>
      <div className='whole-right-nav'>
        <div className='nav-buttons'>
          <Link className='login-button' to='/interest'>Sign in</Link>
          <Link className='getstarted-button' to='/interest'>Sign up</Link>
        </div>
        <div className="burger-icon" onClick={toggleNav}>
          <div className={`line ${isOpen ? 'open' : ''}`}></div>
          <div className={`line ${isOpen ? 'open' : ''}`}></div>
          <div className={`line ${isOpen ? 'open' : ''}`}></div>
        </div>
      </div>
      
      <div className={`mobile-nav-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          {filteredNavLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.path} onClick={() => setIsOpen(false)}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MobileNavigation;
