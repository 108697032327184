import document_main_image from '../../../saas_images/Document_Main.svg'



const landinglinksa = [
    {
        name: "Documents",
        title: "Product Work, Simplified",
        description: "Maximize your impact by freeing up valuable time, allowing you to focus on the most critical and high-priority tasks. Let automation handle the rest, so you can drive results with minimal effort.", 
        main_image: document_main_image,
    }
];

export default landinglinksa;