import './Interest.css'
import img from '../logo_images/Stevie_logo.png'
import { Link } from 'react-router-dom'
import half_screen from '../saas_images/Interest_Half_Screen.svg'
import { useState } from 'react'

function Interest() {
    const [email, setEmail] = useState('');

    return (
        <div className="Interest">
            <Link to='/'><img src={img} alt='logo' className='interest-logo'></img></Link>
            <div className="Interest-left">
                <h1>Stevie gives you back your time </h1>
                <p className='interest-description'>
                    At Stevie, we’re focused on creating tools that let Product Managers concentrate on what truly matters.
                    As we gear up for our first release, we’d love your feedback. Sign up to stay updated on our launch,
                    and let us know if we’re on the right track. If we’re missing something crucial, share your thoughts
                    through our roadmap request — your input could guide our next steps.
                </p>
                <p className='interest-description-mobile'>
                    At Stevie, we’re focused on creating tools that let Product Managers concentrate on what truly matters.
                    As we gear up for our first release, we’d love your feedback. Sign up to stay updated on our launch!
                </p>
                <form className='input-button-container' name="sign-up" method="POST" action='/'>
                    <input type="hidden" name="form-name" value="sign-up" />
                    <input
                        placeholder='Enter your email'
                        className='interest-input'
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  // Update to use e.target.value
                    />
                    <button
                        type="submit"
                        disabled={email === ''}  // Disable on loading or success
                        className={`interest-button ${email === '' ? 'disabled' : ''}`}
                    >
                        Sign up
                    </button>
                </form>

                <p className='disclaimer'> Don't like what you're seeing? Post a request on our <a data-canny-link className='disclaimer-link' href="https://stevie.canny.io/feature-requests">Canny roadmap</a></p>  
            </div>
            <div className="Interest-right">
                <img src={half_screen} alt='half_screen_landing'/>
            </div>
        </div>
    );
}

export default Interest;
