import './LandingBlock.css'

function LandingBlock({ title, description, image, reverse }) {
    return (
        <div className={`LandingBlock ${reverse ? 'row-reverse' : 'row'}`}>
            <div className='LandingBlock-content'>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            <img src={image} className='LandingBlock-image' alt={image}/>
        </div>
    );
}

export default LandingBlock;