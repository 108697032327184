import theo_image from '../about_us_images/Theo.png'
import ravi_image from '../about_us_images/Ravi.png'
import james_image from '../about_us_images/James.png'


const about_us = [
    {
        "name": "Theo Walcot", 
        "position": "Founder", 
        "description":"",
        "image": theo_image  
    },
    { 
        "name": 'Ravi Ambasana', 
        "position": "Product and Engineering",
        "description":"",
        "image": ravi_image  
    },
    { 
        "name": 'James Parison', 
        "position": "Design",
        "description":"yes",
        "image": james_image
    }
];

export default about_us;