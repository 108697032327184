import './Pricing.css';
import React, { useState } from 'react';
import PricingCard from '../components/Pricing/PricingCard';
import GeneralCardLink from '../components/General/GeneralCardLink';

function Pricing() {
  const [active, setActive] = useState('Yearly')

  const pricing_cards = [
    {
      "name": "Free",
      "description": "Set up your feedback portal with just the basics",
      "monthly_price":0,
      "yearly_price":0,
      "button_name": "Get Started",
      "features": ["Unlimited product documentation", "Competitor analysis", "Market research", "AI prioritisation", "AI risk analysis"]
    },
    {
      "name": "Starter",
      "description": "Get more out of your feedback with advanced tools",
      "monthly_price": 99,
      "yearly_price": 79,
      "button_name": "Start trial",
      "features": ["Everything in Free", "Integrations", "Unlimited smart actions", "Higher quality LLM", "Higher support"]
    },
    {
      "name": "Pro",
      "description": "Scale insights across your team with integrations and automations",
      "monthly_price": 399,
      "yearly_price": 359,
      "button_name": "Start trial",
      "features": ["Everything in Starter", "Product courses", "Teams and sharing", "Highest quality LLM", "Personalised account manager"]
    },
  ]

    return (
        <div className="Pricing">
          <div className="Pricing-header">
            <h1>Stevie costs less than the time it saves</h1>
            <p>Start free and see how you could benefit</p>
          </div>
          <div className='pricing-toggle'>
            <button className={`year-button ${active === 'Yearly' && 'active'}`} onClick={()=>setActive('Yearly')}>Yearly up to 20% off!</button>
            <button className={`month-button ${active === 'Monthly' && 'active'}`} onClick={()=>setActive('Monthly')}>Monthly</button>
          </div>
          <div className='pricing-card-container'>
            {pricing_cards.map((card,index) => (
              <PricingCard name={card.name} description={card.description} price={active === 'Yearly' ? card.yearly_price : card.monthly_price} button_name={card.button_name} features={card.features}/>
            ))}
          </div>
          <GeneralCardLink header='Get started with Stevie for free' button_title='Get started' button_link='/interest'/>
        </div>
    );
  }
  
  export default Pricing;