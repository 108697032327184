import './LandingPageC.css'
import NavigationLanding from '../NavigationLanding';
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import landinglinksc from './landinglinks-c.js';


function LandingPageC() {
    const [email, setEmail] = useState('');

    const { landingName } = useParams();

    const feature = landinglinksc.find(f => f.name.toLowerCase() === landingName.toLowerCase());

    if (!feature) {
        return <p>Feature not found.</p>;
    }

    return (
        <>
        <NavigationLanding/>
        <div className="LandingPageC">
            <div className="LandingPage-leftC">
                <h1>{feature.title}</h1>
                <div>
                    <p>{feature.description}</p>
                    <p>{feature.description1}</p>
                    <p>{feature.description2}</p>
                </div>              
                <form className='landing-button-containerC' name="landing-c" method="POST" action='/'>
                    <input type="hidden" name="form-name" value="landing-c" />
                    <input
                        placeholder='Enter your email'
                        className='landing-inputC'
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  // Update to use e.target.value
                    />
                    <button
                        type="submit"
                        disabled={email === ''}  // Disable on loading or success
                        className={`landing-buttonC ${email === '' ? 'disabled' : ''}`}
                    >
                        Sign up
                    </button>
                </form>
                <p className='disclaimerC'> Don't like what you're seeing? Post a request on our <a data-canny-link className='disclaimer-link' href="https://stevie.canny.io/feature-requests">Canny roadmap</a></p>  

            </div>
            <div className="LandingPage-rightC">
                <img alt='landing-image' src={feature.main_image} className='landing-imageC'/>
            </div>
        </div>
        </>
    );
  }
  
  export default LandingPageC;