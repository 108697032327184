import { Home as HomeIcon, Person as PersonIcon, TrendingUp as TrendingUpIcon, Search as SearchIcon, PriorityHigh as PriorityHighIcon, Warning as WarningIcon } from '@mui/icons-material'; // Import necessary icons
import './FeatureCard.css'

const iconMap = {
    document: <HomeIcon />,
    user: <PersonIcon />,
    competitor: <TrendingUpIcon />,
    market: <SearchIcon />,
    priority: <PriorityHighIcon />,
    risk: <WarningIcon />,
    // Add more icons as needed
};

function FeatureCard({ icon_name, header, description, isActive, onClick }) {
    const IconComponent = iconMap[icon_name] || <HomeIcon />; // Default icon

    return (
        <div 
            className={`FeatureCard ${isActive ? 'feature-active' : ''}`}
            onClick={onClick}
        >
            <div className="icon-container">
                {IconComponent}
            </div>
            <h5>{header}</h5>
            <p>{description}</p>
        </div>
    );
}

export default FeatureCard;
