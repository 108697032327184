import './PricingCard.css'
import DoneIcon from '@mui/icons-material/Done';

function PricingCard({name, description, price, button_name, features}) {
    return (
        <div className="PricingCard">
            <h2>{name}</h2>
            <p>{description}</p>
            <div className='money-pricing-card'>
                <h3>${price}</h3>
                <p>/month</p>
            </div>
            <button>{button_name}</button>
            <h4>What you get:</h4>
            <div className='features-container'>
            {features.map((feature,index) => (
                <div className='feature-pricing-card'>
                    <DoneIcon/>
                    <p>{feature}</p>
                </div>
            ))}
            </div>
        </div>
    );
  }
  
  export default PricingCard;