import './LandingPageB.css';
import NavigationLanding from '../NavigationLanding';
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import landinglinksb from './landinglinks-b';


function LandingPageB() {
    const [email, setEmail] = useState('');

    const { landingName } = useParams();

    const feature = landinglinksb.find(f => f.name.toLowerCase() === landingName.toLowerCase());

    if (!feature) {
        return <p>Feature not found.</p>;
    }

    return (
        <>
        <NavigationLanding/>
        <div className="LandingPageB">
            <div className="tagline_descriptor-landing-b">
                <h2 className='tagline-landing-b'>{feature.title}</h2>
                <p className='descriptor-landing-b'>{feature.description}</p>
            </div>
            <form className='landing-button-containerB' name="landing-b" method="POST" action='/'>
                 <input type="hidden" name="form-name" value="landing-b" />
                <input
                    placeholder='Enter your email'
                    className='landing-inputB'
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}  // Update to use e.target.value
                />
                <button
                    type="submit"
                    disabled={email === ''}  // Disable on loading or success
                    className={`landing-buttonB ${email === '' ? 'disabled' : ''}`}
                >
                    Sign up
                </button>
            </form>
            <img className='main-image-landing-b' alt='main-image' src={feature.main_image}/>
        </div>
        </>
    );
}

export default LandingPageB;