import './AboutUs.css'
import { Link } from 'react-router-dom';
import GeneralCardLink from '../components/General/GeneralCardLink'
import about_us from '../data/about_us';

function AboutUs() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
      <div className="AboutUs">
        <h2>About Us</h2>
        <p>As a product manager, managing countless tasks can be overwhelming. Stevie is here to change that. With a foundation built on cutting-edge generative AI, product management is our expertise. We're using our technological prowess to empower you, allowing you to focus on what truly matters: caring for customers and discovering their problems.</p>
        <h2>Our team</h2>
        <p>Stevie’s team is small but mighty. We're a fully bootstrapped, remote team, each of us excelling in our respective fields. Together, we’re a powerhouse, laser-focused on creating the most intuitive product management experiences, helping product managers streamline their work, and achieve remarkable results.</p>
        <div className='about-us-container'>
          {about_us.map((person,index) => (
            <div className='person-container'>
              <div className='person-image-container'>
                <img src={person.image} alt={person.name} className='person-image'/>
              </div>
              <h6>{person.name}</h6>
              <p>{person.position}</p>
            </div>
          ))}
        </div>
        <Link className='aboutus-link' to='/careers'>
          <p>JOIN THE TEAM</p>
          <p>{">"}</p>
        </Link>
        <GeneralCardLink header='Check out our product' button_title='Go to product' button_link='/features/documents' onClick={scrollToTop}/>
      </div>
  );
}

export default AboutUs;
