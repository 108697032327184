import document_main_image from '../saas_images/Document_Main.svg'
import document_first from '../saas_images/Document_First.svg'
import document_second from '../saas_images/Document_Third.svg'
import document_third from '../saas_images/Document_Second.svg'

import interviews_main_image from '../saas_images/Interviews_Main.svg'
import interviews_first from '../saas_images/Interviews_First.svg'
import interviews_second from '../saas_images/Interviews_Second.svg'

import competitors_main_image from '../saas_images/Competitors_Main.svg'
import competitors_first from '../saas_images/Competitors_First.svg'
import competitors_second from '../saas_images/Competitors_Second.svg'

import market_main_image from '../saas_images/Market_Main.svg'
import market_first from '../saas_images/Market_First.svg'
import market_second from '../saas_images/Market_Second.svg'

import prioritise_main_image from '../saas_images/Prioritise_Main.svg'
import prioritise_first from '../saas_images/Prioritise_First.svg'
import prioritise_second from '../saas_images/Prioritise_Second.svg'

import risk_main_image from '../saas_images/Risk_Main.svg'
import risk_first from '../saas_images/Risk_First.svg'
import risk_second from '../saas_images/Risk_Second.svg'

import learning_main_image from '../saas_images/Learning_Main.svg'
import learning_first from '../saas_images/Learning_First.svg'
import learning_second from '../saas_images/Learning_Second.svg'


const feature_links = [
    {
        name: "Documents",
        path: "/features/documents",
        section: "Summarize", 
        description: "Build all your product documents at the click of a button", 
        icon_name: "document",
        main_image: document_main_image,
        components: [
            {
                feature_name:"Automated Document Generation",
                feature_description: "Instantly create comprehensive product documents such as user stories, release notes, and design instructions with a single click.",
                image: document_first
            },
            {
                feature_name:"Project Artefact Organization",
                feature_description: "Organize your projects with artefacts by pulling in Figma designs and discovery sources, creating a unified repository for all your project needs.",
                image: document_second
            },
            {
                feature_name:"Customizable Document Templates",
                feature_description: "Set and share customizable document templates at a senior level to ensure consistency and efficiency across your team, empowering junior PMs to follow structured approaches.",
                image: document_third

            }
        ]
    },
    {
        name: "Interviews",
        path: "/features/interviews",
        section: "Summarize",
        description: "Capture and analyze user feedback seamlessly.",
        icon_name: "user",
        main_image: interviews_main_image,
        components: [
            {
                feature_name: "Transcription and Summarization",
                feature_description: "Automatically transcribe user interviews and summarize key product insights.",
                image: interviews_first
            },
            {
                feature_name: "Smart Triggers",
                feature_description: "Receive smart reminders on important questions to ask during interviews, ensuring you capture all necessary information.",
                image: interviews_second

            },
        ]
    },
    {
        name: "Competitors",
        path: "/features/competitors",
        section: "Automate",
        description: "Stay ahead by tracking your competitors.",
        icon_name: "competitor",
        main_image: competitors_main_image,
        components: [
            {
                feature_name: "Competitor Monitoring and Notifications",
                feature_description: "Set your competitors and receive real-time updates on their change logs via notifications.",
                image: competitors_first
            },
            {
                feature_name: "Cross-Industry Inspiration",
                feature_description: "Create groups of competitors from different industries to gather design and product inspiration.",
                image: competitors_second
            }
        ]
    },
    { 
        name: "Market", 
        path: "/features/market", 
        section: "Automate", 
        description: "Conduct comprehensive market research effortlessly.", 
        icon_name: "market",
        main_image: market_main_image,
        components: [
            {
                feature_name: "Questionnaire-Based Personalisation",
                feature_description: "Complete a tailored questionnaire to receive customised market insights specific to your industry.",
                image: market_first
            },
            {
                feature_name: "Personalised Market Research Plans",
                feature_description: "Receive AI market research plans crafted specifically for you, based on the information you provide.",
                image: market_second
            }
        ]
     },
    { 
        name: "Prioritisation", 
        path: "/features/prioritisation", 
        section: "Automate", 
        description: "Prioritise tasks to focus on what's important.", 
        icon_name: "priority",
        main_image: prioritise_main_image,
        components: [
            {
                feature_name: "Focussed on North Star and Business Objectives",
                feature_description: "Align your product strategy with your North Star metric and key business objectives to drive impactful results.",
                image: prioritise_first
            },
            {
                feature_name: "AI-Driven Task Prioritisation",
                feature_description: "Leverage AI to automatically prioritise tasks, ensuring alignment with your business goals and focusing on the right objectives.",
                image: prioritise_second
            }
        ]
    },
    { 
        name: "Risks", 
        path: "/features/risks", 
        section: "Automate", 
        description: "Assess risks in the smartest way possible.", 
        icon_name: "priority",
        main_image: risk_main_image,
        components: [
            {
                feature_name: "Intelligent Prioritisation",
                feature_description: "Optimise task prioritisation by analysing ticket features through intelligent AI algorithms.",
                image: risk_first
            },
            {
                feature_name: "AI-Driven Risk Identification",
                feature_description: "Generate potential risks for product managers using AI, giving them the opportunity to de-risk them effectively.",
                image: risk_second
            }
        ]
     },
    { 
        name: "Learning", 
        path: "/features/learning", 
        section: "Summarize", 
        description: "Learn how to become a product manager with real world experience.", 
        icon_name: "user",
        main_image: learning_main_image,
        components: [
            {
                feature_name: "Case Studies Across the Site",
                feature_description: "Leverage pre-built data to generate documents, analyse competitors, and evaluate risks.",
                image: learning_first
            },
            {
                feature_name: "Custom-Built Courses",
                feature_description: " Interactive, Duolingo-style courses tailored specifically for Product Managers, helping you master key skills step by step.",
                image: learning_second
            }
        ]
     },
];

export default feature_links;