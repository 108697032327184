import document_main_image from '../../../saas_images/Document_Main.svg'



const landinglinksb = [
    {
        name: "Documents",
        title: "Have all your product tasks done for you",
        description: "Connect your product resources, and Stevi will generate any product documentation you need—user stories, go-to-market strategies, release notes, and more.", 
        main_image: document_main_image,
    }
];

export default landinglinksb;