import WebNavigation from "./WebNavigation";
import MobileNavigation from "./MobileNavigation";
import './Navigation.css'

function Navigation() {
  return (
    <>
    <div className='web-nav-container'>
        <WebNavigation/>
    </div>
    <div className='mobile-nav-container'>
        <MobileNavigation/>
    </div>
    </>
  );
}

export default Navigation;
