import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Navigation from './components/Navigation/Navigation';
import Product from './pages/Product';
import Pricing from './pages/Pricing';
import AboutUs from './pages/AboutUs';
import Interest from './pages/Interest';
import Careers from './pages/Careers';
import Security from './pages/Security';
import TermsAndConditions from './pages/TermsAndConditions';
import FeatureDetail from './pages/FeatureDetail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LandingPageA from './pages/landing/landing-a/LandingPageA'
import LandingPageB from './pages/landing/landing-b/LandingPageB'
import LandingPageC from './pages/landing/landing-c/LandingPageC';
import Home from './pages/Home';
import './App.css';
import Footer from './components/Footer/Footer';
import ScrollIntoView from './components/ScrollIntoView';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <ScrollIntoView />
      {!location.pathname.startsWith('/landing') && location.pathname !== '/interest' && <Navigation />}
      <div className="layout">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/interest" element={<Interest />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/security" element={<Security />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/features/:featureName" element={<FeatureDetail />} />
          <Route path="/landing/a/:landingName" element={<LandingPageA />} /> 
          <Route path="/landing/b/:landingName" element={<LandingPageB />} /> 
          <Route path="/landing/c/:landingName" element={<LandingPageC />} /> 
        </Routes>
      </div>
      {!location.pathname.startsWith('/landing') && location.pathname !== '/interest' && <Footer />}
      </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
