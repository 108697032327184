import { useParams } from 'react-router-dom';
import './FeatureDetail.css';
import { FaCreditCard } from 'react-icons/fa';
import GeneralCardLink from '../components/General/GeneralCardLink';
import LandingBlock from '../components/General/LandingBlock';
import feature_links from '../data/feature_links';
import { Link } from 'react-router-dom';


function FeatureDetail() {
  const { featureName } = useParams();

  const feature = feature_links.find(f => f.name.toLowerCase() === featureName.toLowerCase());

  if (!feature) {
    return <p>Feature not found.</p>;
  }

  return (
    <>
        <div className="Feature">
            <div className="tagline_descriptor">
                <h2 className='feature-tagline'>{feature.name}</h2>
                <p className='feature-descriptor'>{feature.description}</p>
            </div>
            <img src={feature.main_image} alt='main-image' className='feature-main-image'/>
            <div className="buttons-container">
                <div className='button-only-container'>
                    <Link className='home-getting-started' to='/interest'>Get started</Link>
                    <Link className='home-book-demo' to='/interest'>Book demo</Link>
                </div>
                <p className='no-credit-card'>
                    <FaCreditCard className='credit-card-icon' /> No credit card required
                </p>
            </div>
        </div>
        <div className='second-Feature'>
            <div className='landing-block-container'>
              {feature.components.map((feature, index) => (
                  <LandingBlock 
                      key={feature.feature_name} 
                      title={feature.feature_name} 
                      description={feature.feature_description}
                      image={feature.image} 
                      reverse={index % 2 !== 0} // Alternate flex-direction
                  />
              ))}
            </div>
        </div>
        <div className='third-Feature'>
            <div className='card-container'>
                <GeneralCardLink header="Ready to get started?" button_title="Get started" button_link="/interest"/>
            </div>
        </div>
        </>
  );
}

export default FeatureDetail;