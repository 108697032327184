import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="Terms">
      <div className="terms-container">
        <h2>Terms of Service</h2>
        <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the https://stevie.io website (the "Service") operated by Stevie Inc ("us", "we", or "our").</p>
        <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service.</p>
        <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.</p>

        <h3>Communications</h3>
        <p>By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>

        <h3>Subscriptions</h3>
        <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
        <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Stevie Inc cancels it. You may cancel your Subscription renewal by contacting Stevie Inc customer support team.</p>
        <p>A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide Stevie Inc with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Stevie Inc to charge all Subscription fees incurred through your account to any such payment instruments.</p>
        <p>Should automatic billing fail to occur for any reason, Stevie Inc will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>

        <h3>Free Trial</h3>
        <p>Stevie Inc may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").</p>
        <p>You may be required to enter your billing information in order to sign up for the Free Trial.</p>
        <p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by Stevie Inc until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>
        <p>At any time and without notice, Stevie Inc reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>

        <h3>Fee Changes</h3>
        <p>Stevie Inc, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
        <p>Stevie Inc will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
        <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>

        <h3>Refunds</h3>
        <p>Certain refund requests for Subscriptions may be considered by Stevie Inc on a case-by-case basis and granted at the sole discretion of Stevie Inc.</p>

        <h3>Content</h3>
        <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>
        <p>By posting Content on or through the Service, You represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
        <p>You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.</p>
        <p>Stevie Inc has the right but not the obligation to monitor and edit all Content provided by users.</p>
        <p>In addition, Content found on or through this Service are the property of Stevie Inc or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>

        <h3>Accounts</h3>
        <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</p>
        <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
        <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
        <p>We reserve the right to refuse service, terminate accounts, remove or edit content in our sole discretion.</p>

        <h3>Intellectual Property</h3>
        <p>The Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of Stevie Inc and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Stevie Inc.</p>

        <h3>Links To Other Web Sites</h3>
        <p>Our Service may contain links to third-party websites or services that are not owned or controlled by Stevie Inc.</p>
        <p>Stevie Inc has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
        <p>You acknowledge and agree that Stevie Inc shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.</p>
        <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>

        <h3>Termination</h3>
        <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
        <p>If you wish to terminate your account, you may simply discontinue using the Service.</p>
        <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

        <h3>Indemnification</h3>
        <p>You agree to defend, indemnify and hold harmless Stevie Inc and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees), arising from or in connection with a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.</p>

        <h3>Limitation Of Liability</h3>
        <p>In no event shall Stevie Inc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service by any third party; and/or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
        <p>Notwithstanding anything to the foregoing, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to us for the Service during the term of subscription.</p>

        <h3>Disclaimer</h3>
        <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Without limiting the foregoing, Stevie Inc does not warrant that the Service will be uninterrupted, timely, secure, or error-free.</p>
        <p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information or content provided by third parties through the Service. You agree that we will not be responsible for any loss or damage of any sort incurred as a result of any such dealings or as the result of the presence of such third parties on the Service.</p>

        <h3>Governing Law</h3>
        <p>These Terms shall be governed and construed in accordance with the laws of the State of New York, United States, without regard to its conflict of law principles.</p>
        <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

        <h3>Changes</h3>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

        <h3>Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us:</p>
        <ul>
          <li>By email: support@stevie.io</li>
          <li>By visiting this page on our website: https://stevie.io/contact</li>
          <li>By phone: (123) 456-7890</li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAndConditions;
