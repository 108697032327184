import './Footer.css';
import logo from '../../logo_images/Stevie_logo.png';
import { Link } from 'react-router-dom';
import feature_links from '../../data/feature_links';
import nav_links from '../../data/nav_links';

function Footer() {
    // Function to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Function to handle click, only scroll if not "Email us"
    const handleClick = (name, event) => {
        if (name !== 'Email us') {
            scrollToTop();
        } else {
            // Prevent default behavior if it's not a proper link (React Router link issue)
            event.preventDefault();
            window.location.href = 'mailto:support@stevie.io';
        }
    };

    return (
        <div className="Footer">
            <div className="footer-main-left">
                <img src={logo} alt='footer-logo' className='footer-logo'/>
                <p className='copyright'>© 2024 Stevie</p>
            </div>
            <div className="footer-main-right">
                <div className="footer-middle">
                    <h5 className="footer-headers">Product</h5>
                    {feature_links.map((feature, index) => (
                        <Link 
                            key={index} 
                            className={`footer-link ${index}`} 
                            to={feature.path} 
                            onClick={(e) => handleClick(feature.name, e)}>
                            {feature.name}
                        </Link>
                    ))}
                </div>
                <div className="footer-right">
                    <h5 className="footer-headers">Resources</h5>
                    {nav_links.map((nav, index) => (
                        nav.name === 'Email us' ? (
                            <a 
                                key={index} 
                                className={`footer-link ${index}`} 
                                href="mailto:support@stevie.io">
                                {nav.name}
                            </a>
                        ) : (
                            <Link 
                                key={index} 
                                className={`footer-link ${index}`} 
                                to={nav.path} 
                                onClick={(e) => handleClick(nav.name, e)}>
                                {nav.name}
                            </Link>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Footer;
