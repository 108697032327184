import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './WebNavigation.css';
import { FaChevronDown } from 'react-icons/fa'; 
import logo from '../../logo_images/Stevie_logo.png';
import { FaFileAlt, FaUser, FaChartLine, FaSearch, FaTasks, FaExclamationTriangle } from 'react-icons/fa';
import feature_links from '../../data/feature_links';
import nav_links from '../../data/nav_links'



const iconMap = {
    document: <FaFileAlt color='#FF7844' />,
    user: <FaUser color='#FF7844'/>,
    competitor: <FaChartLine color='#FF7844'/>,
    market: <FaSearch color='#FF7844'/>,
    priority: <FaTasks color='#FF7844'/>,
    risk: <FaExclamationTriangle color='#FF7844'/>,
};


function WebNavigation() {
    const [product, setProduct] = useState(false);
    const [shadow, setShadow] = useState(false);


    // Group feature links by their section
    const groupedFeatures = feature_links.reduce((acc, feature) => {
        if (!acc[feature.section]) acc[feature.section] = [];
        acc[feature.section].push(feature);
        return acc;
    }, {});

    // Detect scroll to add shadow
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShadow(true);
            } else {
                setShadow(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const filteredNavLinks = nav_links.slice(0, 4);


    return (
        <div className={`Navigation ${shadow ? 'shadow' : ''}`}>
            <Link className="App-header" to='/'>
                <img src={logo} alt='logo' className='logo'/>
            </Link>
            <ul className="nav-link-container">
                {filteredNavLinks.map((link, index) => (
                    <li 
                        key={index} 
                        className="nav-link"
                        onMouseEnter={() => link.symbol && setProduct(true)}
                        onMouseLeave={() => link.symbol && setProduct(false)}
                    >
                        {link.symbol ? (
                            <div className="nav-item-container" style={{ position: 'relative' }}>
                                <Link to={!link.symbol && link.path} className="nav-link-link">
                                    {link.name}
                                    <FaChevronDown className='symbol' />
                                </Link>  
                                {product && (
                                    <div className={`modal ${product ? 'openweb' : ''}`}>
                                        {/* Mapping over each section and its links */}
                                        {Object.keys(groupedFeatures).map((section, sectionIndex) => (
                                            <div key={sectionIndex} className="feature-section">
                                                <h4 className='feature-section-title'>{section}</h4>
                                                <ul>
                                                    {groupedFeatures[section].map((feature, featureIndex) => (
                                                        <li key={featureIndex} className='feature-link-list-item'>
                                                            <Link to={feature.path} className='feature-link' onClick={()=> {setProduct(false)}}>
                                                                <div className='feature-info-header'>
                                                                    {iconMap[feature.icon_name]} {/* Display the icon */}
                                                                    <h4>{feature.name}</h4>
                                                                </div>
                                                                <p>{feature.description}</p>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Link to={link.path} className="nav-link-link">
                                {link.name}
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
            <div className='nav-buttons'>
                <Link className='login-button' to='/interest'>Sign in</Link>
                <Link className='getstarted-button' to='/interest'>Sign up</Link>
            </div>  
        </div>
    );
}

export default WebNavigation;
