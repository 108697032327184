import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './WebLandingNavigation.css';
import logo from '../../logo_images/Stevie_logo.png';

function WebLandingNavigation() {
    const [shadow, setShadow] = useState(false);

    // Detect scroll to add shadow
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShadow(true);
            } else {
                setShadow(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className={`LandingNavigation ${shadow ? 'shadow-landing' : ''}`}>
            <Link className="App-header-Landing" to='/'>
                <img src={logo} alt='logo' className='logo-landing'/>
            </Link>  
        </div>
    );
}

export default WebLandingNavigation;
