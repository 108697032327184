import './Home.css';
import { useState } from 'react';
import { FaCreditCard } from 'react-icons/fa';
import GeneralCardLink from '../components/General/GeneralCardLink';
import FeatureCard from '../components/General/FeatureCard';
import Main_Image from '../saas_images/Main_Image.svg'
import feature_links from '../data/feature_links'
import { Link } from 'react-router-dom';


function Home() {
    const [active, setActive] = useState('Documents')

    const filteredFeatures = feature_links.filter(feature => feature.name !== 'Prioritisation' && feature.name !== 'Risks');

    const activeFeature = filteredFeatures.find(feature => feature.name === active);

    return (
        <>
        <div className="Home">
            <img className='main-image' alt='main-image' src={Main_Image}/>
            <div className="tagline_descriptor">
                <h2 className='tagline'>Product Work, Simplified</h2>
                <p className='descriptor'>Maximize your impact by freeing up valuable time, allowing you to focus on the most critical and high-priority tasks. Let automation handle the rest, so you can drive results with minimal effort.</p>
            </div>
            <div className="buttons-container">
                <div className='button-only-container'>
                    <Link className='home-getting-started' to='/interest'>Get started</Link>
                    <Link className='home-book-demo' to='/interest'>Book demo</Link>
                </div>
                <p className='no-credit-card'>
                    <FaCreditCard className='credit-card-icon' /> No credit card required
                </p>
            </div>
        </div>
        <div className='second-Home'>
            <h2 className='second-Home-h2'>Completes all your product tasks for you</h2>
            <p className='second-home-p'>Focus on what truly matters: engaging with customers, tackling core challenges, and enjoying quality time with your family</p>
            <div className='feature-cards-container'>
                {filteredFeatures.map((feature) => (
                    <FeatureCard
                        key={feature.name}
                        icon_name={feature.icon_name}
                        header={feature.name}
                        description={feature.description}
                        isActive={active === feature.name}
                        onClick={() => setActive(feature.name)}
                    />
                ))}
            </div>
            <div className='active-feature-card'>
                <div className={`active-feature-card-heading-section ${active ? 'activeness' : ''}`}>
                    <h4 className={`active-card-header ${active ? 'activeness' : ''}`}>{activeFeature?.name}</h4>
                    <p className={`active-feature-description ${active ? 'activeness' : ''}`}>{activeFeature?.description}</p>
                    <ul>
                        {activeFeature?.components.map((component,index) => (
                            <li className={`active-feature-description ${active ? 'activeness' : ''} ${index}`}>{component.feature_description}</li>
                        ))}
                    </ul>
                </div>
                <div className='active-feature-image-container'>
                <img src={activeFeature?.main_image} alt={activeFeature?.name} className={`active-feature-image ${active ? 'activeness' : ''}`}/>
                </div>
            </div>
        </div>
        <div className='third-Home'>
            <div className='card-container'>
                <GeneralCardLink header="Ready to get started?" button_title="Get started" button_link="/interest"/>
            </div>
        </div>
        </>
    );
}

export default Home;