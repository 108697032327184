import React from 'react';
import './Security.css';

function Security() {
    return (
        <div className='Security'>
            <div className="security-container">
                <h2>Security</h2>
                <p>We take the security and privacy of your data on Stevie very seriously. We understand the importance of keeping your data private and strive to keep it this way.</p>
                
                <h3>Compliance</h3>
                <p>Stevie is SOC 2 certified. Our report is available on request.</p>
                
                <h3>Team</h3>
                <p>Our engineers have experience working on highly reliable, scalable, and secure systems at companies like Facebook and Google. We always have someone on call to address any issues or outages as fast as possible.</p>
                
                <h3>Hosting</h3>
                <p>Stevie uses Amazon Web Services (AWS) to host all of our applications and static resources. They’re one of the top hosting providers in the world – used by companies like Netflix, Adobe, Snap, and Atlassian.</p>
                <p>Only Stevie engineers have access to these machines, via key-based SSH login. We update system software whenever updates are available.</p>
                <p>To learn more, read about <a href="https://aws.amazon.com/security/" target="_blank" rel="noopener noreferrer">AWS security policies</a>.</p>
                
                <h3>Data</h3>
                <p>Stevie uses Atlas, a product of MongoDB, to host our data. We back up everything continuously to ensure your valuable data stays safe.</p>
                <p>This data is only accessible by the same group of Stevie engineers who have access to hosting machines. Your private data will not be accessed by our team except as absolutely needed to resolve issues.</p>
                <p>To learn more, read about <a href="https://www.mongodb.com/cloud/atlas" target="_blank" rel="noopener noreferrer">MongoDB security policies</a>.</p>
                
                <h3>SSL</h3>
                <p>Our entire website and hosting servers are only accessible via encrypted connections – HTTPS using RSA 2048-bit keys.</p>
                
                <h3>Contact Us</h3>
                <p>If you have any remaining questions or concerns about Stevie’s security, don’t hesitate to reach out to <a href="mailto:support@stevie.io">support@stevie.io</a>.</p>
            </div>
        </div>
    );
}

export default Security;
