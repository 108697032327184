import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MobileLandingNavigation.css';
import logo from '../../logo_images/Stevie_logo.png';
import micro_logo from '../../logo_images/micro_logo.png'

function MobileLandingNavigation() {
  const [shadow, setShadow] = useState(false);

  // Detect scroll to add shadow
  useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setShadow(true);
        } else {
            setShadow(false);
        }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  return (
    <div className={`MobileLandingNavigation ${shadow ? 'shadow' : ''}`}>
      <Link className="Mobile-App-header-Landing" to='/'>
        <img src={logo} alt='logo' className='logo-landing' />
        <img src={micro_logo} alt='logo' className='micro-logo-landing' />
      </Link>
    </div>
  );
}

export default MobileLandingNavigation;
