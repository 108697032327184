import './GeneralCardLink.css'
import { Link } from 'react-router-dom';

function GeneralCardLink({header, button_title, button_link, onClick}) {
    return (
        <div className="GeneralCardLink">
          <h5>{header}</h5>
          <Link to={button_link} onClick={onClick}>{button_title}</Link>
        </div>
    );
  }
  
  export default GeneralCardLink;
  