import interest_main_image from '../../../saas_images/Interest_Half_Screen.svg'



const landinglinksc = [
    {
        name: "Documents",
        title: "Have all your product tasks done for you",
        description: "Connect your product resources, and Stevi will generate any product documentation you need—user stories, go-to-market strategies, release notes, and more.",
        description1: "Connect your product resources, and Stevi will generate any product documentation you need—user stories, go-to-market strategies, release notes, and more.", 
        description2: "Connect your product resources, and Stevi will generate any product documentation you need—user stories, go-to-market strategies, release notes, and more.", 
        main_image: interest_main_image,
    }
];

export default landinglinksc;