import WebLandingNavigation from "./WebLandingNavigation";
import MobileLandingNavigation from "./MobileLandingNavigation";
import './NavigationLanding.css'

function NavigationLanding() {
  return (
    <>
    <div className='web-nav-container-landing'>
        <WebLandingNavigation/>
    </div>
    <div className='mobile-nav-container-landing'>
        <MobileLandingNavigation/>
    </div>
    </>
  );
}

export default NavigationLanding;
