import './LandingPageA.css';
import NavigationLanding from '../NavigationLanding';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import landinglinksa from './landinglinks-a.js';


function LandingPageA() {
    const [email, setEmail] = useState('');

    const { landingName } = useParams();

    const feature = landinglinksa.find(f => f.name.toLowerCase() === landingName.toLowerCase());

    if (!feature) {
        return <p>Feature not found.</p>;
    }


    return (
        <>
        <NavigationLanding/>
        <div className="LandingPageA">
            <img className='main-image-landing-a' alt='main-image' src={feature.main_image}/>
            <div className="tagline_descriptor-landing-a">
                <h2 className='tagline-landing-a'>{feature.title}</h2>
                <p className='descriptor-landing-a'>{feature.description}</p>
            </div>
            <form className='landing-button-containerA' name="landing-a" method="POST" action='/'>
                    <input type="hidden" name="form-name" value="landing-a" />
                    <input
                        placeholder='Enter your email'
                        className='landing-inputA'
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  // Update to use e.target.value
                    />
                    <button
                        type="submit"
                        disabled={email === ''}  // Disable on loading or success
                        className={`landing-buttonA ${email === '' ? 'disabled' : ''}`}
                    >
                        Sign up
                    </button>
                </form>
        </div>
        </>
    );
}

export default LandingPageA;