import './Careers.css'

function Careers() {
    return (
        <div className='Careers'>
            <h1>Open Positions</h1>
            <h6>Currently we don't have any open positions available.</h6>
        </div>
    );
  }
  
  export default Careers;