const nav_links = [
    {"name": "Product", "path": "/features/documents", "symbol":"yes"  },
    { "name": 'Pricing', "path": "/pricing"},
    { "name": 'About Us', "path": "/about-us" },
    { "name": 'Change log', "path": "/interest" },
    { "name": 'Careers', "path": "/careers" },
    { "name": 'Email us', "path": "mailto:support@stevie.io" },
    { "name": 'Security', "path": "/security" },
    { "name": 'Terms and Conditions', "path": "/terms" },
    { "name": 'Privacy policy', "path": "/privacy" },
];

export default nav_links;